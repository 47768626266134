import pxToRem from 'assets/theme-dark/functions/pxToRem';

export default {
  styleOverrides: {
    root: {
      marginTop: 0,
      marginBottom: 0,
      padding: `${pxToRem(8)} ${pxToRem(24)} ${pxToRem(24)}`
    }
  }
};
