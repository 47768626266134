import { FC, useEffect } from 'react';
import { useRouter } from 'next/router';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setLayout } from 'context';

const PageLayout: FC<IPageLayoutProps> = ({ background, children }) => {
  const [, dispatch] = useMaterialUIController();
  const { pathname } = useRouter();

  useEffect(() => {
    setLayout(dispatch, 'page');
  }, [pathname]);

  return (
    <MDBox
      width="100vw"
      height="100%"
      minHeight="100vh"
      bgColor={background}
      sx={{ overflowX: 'hidden' }}
    >
      {children}
    </MDBox>
  );
};

// Setting default values for the props for PageLayout
PageLayout.defaultProps = {
  background: 'default'
};

// Typechecking props for the PageLayout
interface IPageLayoutProps {
  background?: 'white' | 'light' | 'default';
}

export default PageLayout;
