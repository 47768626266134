import { AxiosError } from 'axios';
import { rangeData } from 'constants/dateRange';
import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import addWeeks from 'date-fns/addWeeks';
import format from 'date-fns/format';
import { action, computed, flow, makeObservable, observable, ObservableMap } from 'mobx';
import fetchDeposit, { fetchSummaryStat, IDeposit, IDepositSummaryStat } from 'requests/deposit';

export const depositKeyToLabelMap = {
  inserted_at: 'Timestamp',
  id: 'Tx ID',
  full_name: 'Customer',
  dollar_amount: 'Amount($)',
  amount_paid: 'Amount Paid',
  dollar_processing_fee: 'Processing Fee',
  status: 'Status',
  deposit_type: 'Deposit Type',
  reference: 'Reference',
  dollar_instant_deposit_fee: 'Instant Deposit Fee',
  currency: 'Currency',
  updated_at: 'Updated At'
};

export class DepositStoreImpl {
  deposits: IDeposit[] = [];

  isLoading = {
    fetchDeposit: false
  };

  error: Partial<Error> = {};

  meta: Partial<IPaginatedMeta> = {
    currentPage: 0,
    itemCount: 0
  };

  summaryStat: IDepositSummaryStat = {
    data: [],
    range: {
      end_date: 0,
      start_date: 0
    },
    stat: 'days'
  };

  loading: Partial<{
    deposit: boolean;
    summaryStat: boolean;
  }> = {};

  tableConfig: ISetDateRangeCBArgs & ITableConfig = {
    endDate: rangeData.find((item) => item.label === 'Last 7 Days').endDate,
    startDate: rangeData.find((item) => item.label === 'Last 7 Days').startDate,
    buttonLabel: 'Last 7 Days',
    reset: false
  };

  errors: Partial<{
    summaryStat: Partial<Error>;
  }> = {};

  signals = new ObservableMap<keyof typeof this.isLoading, AbortController>();

  search = '';

  constructor() {
    makeObservable(this, {
      deposits: observable,
      isLoading: observable,
      error: observable,
      errors: observable,
      meta: observable,
      summaryStat: observable,
      tableConfig: observable,
      search: observable,
      setReqError: action.bound,
      setRange: action.bound,
      toggleReset: action.bound,
      setSearch: action.bound,
      fetchStat: flow.bound,
      fetchDeposit: flow.bound,
      getDepositView: computed,
      calculatedDepositSum: computed,
      chartData: computed
    });
  }

  setRange(config: ISetDateRangeCBArgs) {
    this.tableConfig = { ...config, reset: true };
    this.deposits = [];
    this.fetchStat({ start_date: config.startDate.valueOf(), end_date: config.endDate.valueOf() });
  }

  toggleReset(reset: boolean) {
    this.tableConfig.reset = reset;
  }

  setReqError(err: AxiosError, state: string = 'error') {
    if (String(err?.message)?.toLowerCase() === 'canceled') {
      return;
    }

    if (err.response?.status !== 401) {
      this[state] = err;
      setTimeout(() => {
        this[state] = {};
      }, 10000);
    }
  }

  setSearch(search: string) {
    this.search = search;
  }

  *fetchDeposit(params: Partial<IPaginationConfig> = {}, controller?: AbortController) {
    if (this.isLoading.fetchDeposit) {
      this.signals.get('fetchDeposit')?.abort();
      if (controller) this.signals.set('fetchDeposit', controller);
    }
    this.isLoading.fetchDeposit = true;
    try {
      const { data } = yield fetchDeposit(params, controller?.signal);
      this.deposits = data.items;
      this.meta = data.meta;
    } catch (error) {
      this.setReqError(error);
    } finally {
      this.isLoading.fetchDeposit = false;
    }
  }

  *fetchStat(params: Partial<IPaginationConfig> = {}) {
    this.loading.summaryStat = true;
    try {
      const { data } = yield fetchSummaryStat(params);
      this.summaryStat = data;
      this.loading.summaryStat = false;
    } catch (error) {
      this.setReqError(error, 'errors.summaryStat');
    }
  }

  get getDepositView() {
    return this.deposits.map<ITableFormattedDeposit>((deposit) => ({
      id: deposit.id,
      full_name: deposit.user?.identity_document?.full_name || 'Not available',
      deposit_type: deposit.deposit_type,
      dollar_amount: deposit.dollar_amount,
      amount_paid: deposit.amount_paid,
      dollar_instant_deposit_fee: deposit.dollar_instant_deposit_fee,
      dollar_processing_fee: deposit.dollar_processing_fee,
      inserted_at: deposit.inserted_at,
      reference: deposit.reference,
      status: deposit.status.match(/Settlemented/i)
        ? 'settled'
        : deposit.status.match(/Deleted/i)
        ? 'cancelled'
        : deposit.status,
      currency: deposit.currency,
      updated_at: deposit.updated_at
    }));
  }

  get calculatedDepositSum() {
    return this.summaryStat.data.reduce((prev, currVal) => {
      if (!currVal.sum) return prev;
      return parseFloat(currVal.sum) + prev;
    }, 0);
  }

  get chartData(): IChartData {
    const statData = this.summaryStat;
    if (statData.stat === 'days') {
      const res = statData.data.map<IChartData['data'][0]>((day) => ({
        label: format(addDays(statData.range.start_date, day.id - 1), 'd MMM'),
        value: Number(day.sum).toFixed(2)
      }));
      return {
        label: `Summary of ${format(statData.range.start_date, 'PPP')} ~ ${format(
          statData.range.end_date,
          'PPP'
        )}`,
        data: res
      };
    }
    if (statData.stat === 'months') {
      const res = statData.data.map<IChartData['data'][0]>((month) => ({
        label: format(addMonths(statData.range.start_date, month.id - 1), 'MMM'),
        value: Number(month.sum).toFixed(2)
      }));
      return {
        label: `Summary of ${format(statData.range.start_date, 'PPP')} ~ ${format(
          statData.range.end_date,
          'PPP'
        )}`,
        data: res
      };
    }
    if (statData.stat === 'weeks') {
      const res = statData.data.map<IChartData['data'][0]>((weeks) => ({
        label: format(addWeeks(statData.range.start_date, weeks.id - 1), "wo 'week'"),
        value: Number(weeks.sum).toFixed(2)
      }));
      return {
        label: `Summary of ${format(statData.range.start_date, 'PPP')} ~ ${format(
          statData.range.end_date,
          'PPP'
        )}`,
        data: res
      };
    }
    return {
      data: [],
      label: ''
    };
  }
}

const DepositStore = new DepositStoreImpl();

export default DepositStore;
