/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  The linearGradient() function helps you to create a linear gradient color background
 */

function linearGradient(color: string, colorState, angle = 195) {
  return `linear-gradient(${angle}deg, ${color}, ${colorState})`;
}
// linear-gradient(to right, rgba(255, 255, 255, 0), #0d0b0b, rgba(255, 255, 255, 0)) !important
export function linearGradient3scale(color: string, colorState: string, angle = 195) {
  return `linear-gradient(to right, ${color}, ${colorState}, ${color})`;
}

export default linearGradient;
