import LoadingPage from 'components/LoadingPage';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useMemo, useState } from 'react';
import { useStore } from 'store';

const withAuthentication = <P extends Record<string, any>>(
  Component: React.ComponentType<P>
): FC<P> =>
  observer((props) => {
    const {
      AuthStore: { isAuthenticated, isLoading, logout }
    } = useStore();

    const [initializing, setInitializing] = useState(true);

    const { ttl } = useMemo(() => isAuthenticated(), [isAuthenticated]);

    useEffect(() => {
      if (isLoading.verify || isLoading.login || ttl === 0) {
        setInitializing(true);
      } else {
        setTimeout(setInitializing, 50, false);
      }
    }, [ttl, isLoading.login, isLoading.verify]);

    useEffect(() => {
      if (ttl === 0) {
        logout();
      }
    }, [ttl, logout]);

    return <>{initializing ? <LoadingPage fullPage /> : <Component {...(props as P)} />}</>;
  });

export default withAuthentication;
