/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Theme } from '@mui/material/styles/createTheme';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { ComponentsProps } from '@mui/material/styles/props';

export default {
  defaultProps: {
    underline: 'none',
    color: 'inherit'
  }
} as {
  defaultProps?: ComponentsProps['MuiLink'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiLink'];
};
