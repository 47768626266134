import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useStore } from 'store';

const withAuthorization =
  (condition: (authUser: IAuth) => boolean) =>
  <P extends Record<string, any>>(Component: React.ComponentType<P>): FC<P> =>
    observer((props) => {
      const {
        AuthStore: { Auth }
      } = useStore();
      return condition(Auth) ? <Component {...(props as P)} /> : null;
    });

export default withAuthorization;
