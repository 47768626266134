import { AUTH_MS } from 'constants/api';
import { authMS } from 'requests';

export const encryptedLogin = (scrambledCredential: string) =>
  authMS(AUTH_MS.SIGNIN_PASSWORD, {
    headers: {
      'x-auth-hash': scrambledCredential
    }
  });

export const getCheckEmail = (email: string) =>
  authMS(AUTH_MS.CHECK_EMAIL.replace(':email', email));
