import { AUTH_MS } from 'constants/api';
import { authMS } from 'requests';

export interface IVerifyParam {
  code: string;
}

export interface IVerifyResponse {
  access_token: string;
}

const call = (credential: IVerifyParam) =>
  authMS.post<IVerifyResponse>(AUTH_MS.VERIFY_OTP, credential);

export default call;
