import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { FC } from 'react';
// eslint-disable-next-line import/no-named-default
import { default as MUILink, LinkProps as MUILinkProps } from '@mui/material/Link';

interface IBOLinkProps extends MUILinkProps {
  nextHref: NextLinkProps['href'];
}
/**
 * Combined usage of NextJS Link function and MUI Link styling
 */
const BOLink: FC<IBOLinkProps> = ({ children, href, nextHref = href, ref, ...rest }) => (
  <NextLink href={nextHref} passHref>
    <MUILink
      href={typeof nextHref === 'object' ? nextHref?.toString() : nextHref}
      ref={ref}
      component="span"
      {...rest}
    >
      {children}
    </MUILink>
  </NextLink>
);

export default BOLink;
