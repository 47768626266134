import { ReformatEnv } from 'utils/reformat_env.utils';

const STAGING_API_URL = 'https://pbb-dashboard-api.investbamboo.dev';
const AUTH_MS_BASE_URL = 'https://auth-api.investbamboo.dev';

const AUTH_MS_PUBLIC_KEY =
  '-----BEGIN RSA PUBLIC KEY-----\nMIIBCgKCAQEAsF2d9WpHgBqi0Sa2M5mL1Ij/PmQCKyOkTdg46PQvQSYguOV9xtoT\nK+4hwldxvQj74YZO1MZ9ZUiXqLGEJBpWGc7FZIU3rrulkj8s6WoZVz3uwq53QN3j\nW8VWXo89tlnY7eA3YKTiA1uRx/abB4gw67uX7FnDVQmcheitG37HkORFCBWTTFKU\nn8397tdbdXmQxzazmM4wpfUd7EpEFYGfEKEsA/DyAhZdjEbU1CeQHeI9QtcJYmMN\nDiV4SEcVVseSokhdTRW4ElzEdVyfOD27pOCdpRRvjrUunFxwKOTtdJYEPubwM5+z\n2hllzkvMkXF/PJf5U4OthWW9Xv1Qu7HDyQIDAQAB\n-----END RSA PUBLIC KEY-----\n';

const APP_PUBLIC_KEY =
  '-----BEGIN RSA PUBLIC KEY-----\nMIIBCgKCAQEAwIogkOVQNsVo8ngbhWK6lvEbMMNWNzU4303Tvf3MHCvBq5R4Juap\nr+KbMslE4beNM/TZoka+beioos6KE01lXZKx1gOTZ/yHWa/z3AIUbpEGehJUuuoU\n1WRbl/FBTUPdEy6BRlKgCGrFPV1cN2bzayDg8BDVCrVDdVOvNrFZwRCwZE15rkFL\nJl5NY/eWNkp0hTWaavfVGho83bwxo2OrtmOihFcIKdM/LPdEbqOhdP2Eso29Hvjj\njFQx4b7OF67N8RmqhXzCCvfa+lA3Tkj8nnAS2++wZlE5NzUsBP2GAjkfYnekFe+l\nGjyI0ArOtofPU3dNgvw8EpJAVc3JTGbC8QIDAQAB\n-----END RSA PUBLIC KEY-----\n';

const AppConfig = {
  ApiBaseUrl: ReformatEnv(process.env.NEXT_PUBLIC_API_URL || STAGING_API_URL),
  SandboxApiBaseUrl: ReformatEnv('https://pbb-dashboard-sandbox-api.investbamboo.dev'),
  AuthMSBaseUrl: ReformatEnv(process.env.NEXT_PUBLIC_AUTH_MS_BASE_URL || AUTH_MS_BASE_URL),
  AuthMSPublicKey: ReformatEnv(process.env.NEXT_PUBLIC_AUTH_MS_PKC || AUTH_MS_PUBLIC_KEY),
  AppPublicKey: ReformatEnv(process.env.NEXT_PUBLIC_APP_PKC || APP_PUBLIC_KEY)
};

export default AppConfig;
