import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import endOfToday from 'date-fns/endOfToday';
import startOfToday from 'date-fns/startOfToday';

export const isNewRange = (initialRange: ISetDateRangeCBArgs, range: ISetDateRangeCBArgs) => {
  const isSame: Record<keyof ISetDateRangeCBArgs, boolean> = {
    buttonLabel: true,
    startDate: true,
    endDate: true
  };
  if (initialRange.buttonLabel !== range.buttonLabel) {
    isSame.buttonLabel = false;
  }
  if (initialRange.startDate.valueOf() !== range.startDate.valueOf()) {
    isSame.startDate = false;
  }
  if (initialRange.endDate.valueOf() !== range.endDate.valueOf()) {
    isSame.endDate = false;
  }
  const check = Object.values(isSame).every((val) => val);
  return !check;
};
// initialRange.buttonLabel !== range.buttonLabel ||
// initialRange.startDate !== range.startDate ||
// initialRange.endDate !== range.endDate;

interface IDateStaticRange {
  label: TDefaultStaticDate;
  startDate: Date;
  endDate: Date;
}

export type TDefaultStaticDate =
  | 'Today'
  | 'Last 7 Days'
  | 'Last 30 Days'
  | 'Last 3 Months'
  | 'Last 6 Months'
  | 'Last 12 Months'
  | 'Custom';

export const rangeData: IDateStaticRange[] = [
  {
    label: 'Today',
    startDate: startOfToday(),
    endDate: endOfToday()
  },
  {
    label: 'Last 7 Days',
    startDate: addDays(startOfToday(), -6),
    endDate: endOfToday()
  },
  {
    label: 'Last 30 Days',
    startDate: addDays(startOfToday(), -29),
    endDate: endOfToday()
  },
  {
    label: 'Last 3 Months',
    startDate: addMonths(startOfToday(), -3),
    endDate: endOfToday()
  },
  {
    label: 'Last 6 Months',
    startDate: addMonths(startOfToday(), -6),
    endDate: endOfToday()
  },
  {
    label: 'Last 12 Months',
    startDate: addMonths(startOfToday(), -12),
    endDate: endOfToday()
  }
];
