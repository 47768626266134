import { ObservableMap, action, flow, makeObservable, observable, runInAction } from 'mobx';
import { getStocks } from 'requests/stocks';
import { parseError } from 'utils/parseError';

export class StockStoreImpl {
  stocks: IStock[] = [];

  isLoading = {
    getStocks: false
  };

  signals = new ObservableMap<keyof typeof this.isLoading, AbortController>();

  meta: Partial<IPaginatedMeta> = {
    currentPage: 1,
    itemCount: 0
  };

  errors = {
    getStocks: ''
  };

  constructor() {
    makeObservable(this, {
      stocks: observable,
      errors: observable,
      isLoading: observable,
      meta: observable,

      getStocks: flow.bound,

      clearError: action.bound,
      resetMeta: action.bound
    });
  }

  clearError(errorItem: keyof typeof this.errors, delay = 3000) {
    setTimeout(() => {
      runInAction(() => {
        this.errors[errorItem] = '';
      });
    }, delay);
  }

  resetMeta() {
    this.meta = {
      currentPage: 1,
      itemCount: 0
    };
  }

  *getStocks(pagination?: IPaginationConfig, controller?: AbortController) {
    if (this.isLoading.getStocks) {
      this.signals.get('getStocks')?.abort();
      if (controller) this.signals.set('getStocks', controller);
    }

    this.isLoading.getStocks = true;
    try {
      const { data } = (yield getStocks(pagination, controller.signal)) as {
        data: IPaginatedEndpointResp<IStock>;
      };

      this.stocks = data.items;
      this.meta = data.meta;
    } catch (error) {
      this.errors.getStocks = parseError(error);
      this.clearError('getStocks');
    } finally {
      this.isLoading.getStocks = false;
    }
  }
}

const StocksStore = new StockStoreImpl();

export default StocksStore;
