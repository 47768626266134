import rgba from 'assets/theme-dark/functions/rgba';
import pxToRem from 'assets/theme-dark/functions/pxToRem';

type TBoxShadowRadius = [blur: number, spread: number] | [];
type TBoxShadowOffset = [x: number, y: number] | [];

function boxShadow(
  offset: TBoxShadowOffset = [],
  radius: TBoxShadowRadius = [],
  color: string,
  opacity: number,
  inset: 'inset' | '' = ''
) {
  const [x, y] = offset;
  const [blur, spread] = radius;

  return `${inset} ${pxToRem(x)} ${pxToRem(y)} ${pxToRem(blur)} ${pxToRem(spread)} ${rgba(
    color,
    opacity
  )}`;
}

export default boxShadow;
