import { AxiosError } from 'axios';
import format from 'date-fns/format';
import { action, computed, flow, makeObservable, observable, ObservableMap } from 'mobx';
import fetchTrades, {
  fetchSummaryStat,
  getLifeTimeTradeSum,
  ITradesHistory,
  ITradesSummaryStat
} from 'requests/trades';
import { currency, getDateRangeWithLabel } from 'utils';

export const tradeKeyToLabelMap = {
  inserted_at: 'Timestamp',
  full_name: 'Customer',
  side: 'Trade',
  dollar_fee: 'Fee',
  transaction_value: 'Amount',
  stock_symbol: 'Stock',
  name: 'Stock',
  status: 'Status',
  user_currency_fee: 'Fee(₦)',
  user_currency_transaction_value: 'Amount(₦)',
  user_currency_price_per_share: 'Price Per Share(₦)',
  price_per_share: 'Price Per Share($)',
  dw_order_id: 'Order ID',
  updated_at: 'Updated At'
};

const { endDate, label, startDate } = getDateRangeWithLabel('Last 7 Days');
export class TradeStoreImpl {
  trades: ITradesHistory[] = [];

  isLoading = {
    fetchTrades: false
  };

  lifeTimetradeSum: string = '₦0.00';

  error: Partial<Error> = {};

  signals = new ObservableMap<keyof typeof this.isLoading, AbortController>();

  search = '';

  meta: Partial<IPaginatedMeta> = {
    currentPage: 0,
    itemCount: 0
  };

  summaryStat: ITradesSummaryStat = {
    returnedSum: {
      buy_sum: 0,
      sell_sum: 0
    },
    range: {
      end_date: 0,
      start_date: 0
    }
  };

  loading: Partial<{
    deposit: boolean;
    summaryStat: boolean;
    lifeTimeSum: boolean;
  }> = {};

  tableConfig: ISetDateRangeCBArgs & ITableConfig = {
    endDate,
    startDate,
    buttonLabel: label,
    reset: false
  };

  errors: Partial<{
    summaryStat: Partial<Error>;
    lifeTimeSum: Partial<Error>;
  }> = {};

  constructor() {
    makeObservable(this, {
      trades: observable,
      isLoading: observable,
      error: observable,
      errors: observable,
      meta: observable,
      summaryStat: observable,
      tableConfig: observable,
      search: observable,
      lifeTimetradeSum: observable,
      loading: observable,
      setReqError: action.bound,
      setRange: action.bound,
      toggleReset: action.bound,
      setSearch: action.bound,
      fetchStat: flow.bound,
      fetchLifeTimeTradeSum: flow.bound,
      fetchTrades: flow.bound,
      getTradesView: computed,
      getLabel: computed
    });
  }

  setRange(config: ISetDateRangeCBArgs) {
    this.tableConfig = { ...config, reset: true };
    this.trades = [];
    this.fetchStat({ start_date: config.startDate.valueOf(), end_date: config.endDate.valueOf() });
  }

  toggleReset(reset: boolean) {
    this.tableConfig.reset = reset;
  }

  setReqError(err: AxiosError, state: string = 'error') {
    if (String(err?.message)?.toLowerCase() === 'canceled') {
      return;
    }

    if (err.response?.status !== 401) {
      this[state] = err;
      setTimeout(() => {
        this[state] = {};
      }, 10000);
    }
  }

  setSearch(search: string) {
    this.search = search;
  }

  *fetchTrades(params: Partial<IPaginationConfig> = {}, controller?: AbortController) {
    if (this.isLoading.fetchTrades) {
      this.signals.get('fetchTrades')?.abort();
      if (controller) this.signals.set('fetchTrades', controller);
    }
    this.isLoading.fetchTrades = true;
    try {
      const { data } = yield fetchTrades(params, controller?.signal);
      this.trades = data.items;
      this.meta = data.meta;
    } catch (error) {
      this.setReqError(error);
    } finally {
      this.isLoading.fetchTrades = false;
    }
  }

  *fetchLifeTimeTradeSum() {
    this.loading.lifeTimeSum = true;
    try {
      const { data } = yield getLifeTimeTradeSum();
      this.lifeTimetradeSum = currency('NGN').format(data.sum);
      this.loading.lifeTimeSum = false;
    } catch (error) {
      this.setReqError(error, 'errors.lifeTimeSum');
    }
  }

  *fetchStat(params: Partial<IPaginationConfig> = {}) {
    this.loading.summaryStat = true;
    try {
      const { data } = yield fetchSummaryStat(params);
      this.summaryStat = data;
      this.loading.summaryStat = false;
    } catch (error) {
      this.setReqError(error, 'errors.summaryStat');
    }
  }

  get getTradesView() {
    return this.trades.map<ITableFormattedTrades>((trade) => ({
      id: trade.id,
      dollar_fee: trade.dollar_fee,
      full_name: trade.user?.identity_document?.full_name || 'Not Available',
      inserted_at: trade.inserted_at,
      price_per_share: trade.price_per_share,
      side: trade.side,
      status: trade.status,
      stock_symbol: trade.stock_symbol,
      transaction_value: trade.transaction_value,
      user_currency_fee: trade.user_currency_fee,
      user_currency_price_per_share: trade.user_currency_price_per_share,
      user_currency_transaction_value: trade.user_currency_transaction_value,
      name: trade.stock?.name || 'Not Available',
      dw_order_id: trade.dw_order_id || 'Not Available',
      updated_at: trade.updated_at
    }));
  }

  get getLabel() {
    const statData = this.summaryStat;
    return this.loading.summaryStat
      ? 'Loading...'
      : `${format(statData.range.start_date, 'PPP')} ~ ${format(statData.range.end_date, 'PPP')}`;
  }
}

const TradeStore = new TradeStoreImpl();

export default TradeStore;
