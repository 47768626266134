import Drawer, { DrawerProps } from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';

interface IOwnerState {
  transparentSidenav: boolean;
  whiteSidenav: boolean;
  miniSidenav: boolean;
  darkMode: boolean;
}

interface StyledDrawerProps extends DrawerProps {
  ownerState: IOwnerState;
}

export default styled(Drawer)<StyledDrawerProps>(({ theme, ownerState }) => {
  const { palette, boxShadows, transitions, breakpoints, functions } = theme;
  const { transparentSidenav, miniSidenav, darkMode } = ownerState;

  const sidebarWidth = 250;
  const { transparent, gradients, background } = palette;
  const { xxl, navbarBoxShadow } = boxShadows;
  const { pxToRem, linearGradient, rgba } = functions;

  const backgroundValue = darkMode
    ? transparent.main
    : linearGradient(gradients.primary.main, gradients.primary.state);

  // styles for the sidenav when miniSidenav={false}
  const drawerOpenStyles = () => ({
    background: backgroundValue,
    transform: 'translateX(0)',
    transition: transitions.create('transform', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter
    }),

    [breakpoints.up('xl')]: {
      boxShadow: transparentSidenav ? 'none' : xxl,
      marginBottom: transparentSidenav ? 0 : 'inherit',
      left: '0',
      width: sidebarWidth,
      transform: 'translateX(0)',
      transition: transitions.create(['width', 'background-color'], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen
      })
    },
    [breakpoints.down('xl')]: {
      ...(darkMode
        ? {
            backgroundColor: rgba(background.default, 0.8),
            backdropFilter: `saturate(200%) blur(${pxToRem(10)})`,
            boxShadow: navbarBoxShadow
          }
        : {})
    }
  });

  // styles for the sidenav when miniSidenav={true}
  const drawerCloseStyles = () => ({
    background: backgroundValue,
    transform: `translateX(${pxToRem(-320)})`,
    transition: transitions.create('transform', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter
    }),

    [breakpoints.up('xl')]: {
      boxShadow: transparentSidenav ? 'none' : xxl,
      marginBottom: transparentSidenav ? 0 : 'inherit',
      left: '0',
      width: pxToRem(96),
      overflowX: 'hidden',
      transform: 'translateX(0)',
      transition: transitions.create(['width', 'background-color'], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.shorter
      })
    }
  });

  return {
    '& .MuiDrawer-paper': {
      boxShadow: xxl,
      border: 'none',
      ...(miniSidenav ? drawerCloseStyles() : drawerOpenStyles())
    }
  };
});
