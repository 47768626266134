import { Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system/styleFunctionSx';

interface ISideNavLogoLabelOwnerState {
  miniSidenav: boolean;
}

export default function sidenavLogoLabel(
  theme: Theme,
  ownerState: ISideNavLogoLabelOwnerState,
  leaveFill?: string
): SystemStyleObject<Theme> {
  const { transitions, breakpoints, palette } = theme;
  const { miniSidenav } = ownerState;

  return {
    cursor: 'pointer',
    '& svg': {
      width: 180
    },
    '& path#leaves': {
      fill: leaveFill || palette.logo.leaves
    },
    '& path#bamboo-typo': {
      fill: palette.logo.bamboo
    },
    transition: transitions.create('opacity', {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.standard
    }),

    [breakpoints.up('xl')]: {
      opacity: miniSidenav ? 0 : 1
    }
  };
}
