import React, { forwardRef } from 'react';

// Material Dashboard 2 React contexts
import { useMaterialUIController } from 'context';
import { ButtonProps } from '@mui/material/Button';
import { ButtonBaseProps } from '@mui/material/ButtonBase';
import MDButtonRoot, { IMDButtonProps } from './MDButtonRoot';

const MDButton = forwardRef<
  any,
  IMDButtonProps & ButtonBaseProps & Omit<ButtonProps, 'variant' | 'color'>
>(({ color, variant, size, circular, iconOnly, children, ...rest }, ref) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <MDButtonRoot
      {...rest}
      ref={ref}
      color="primary"
      variant={variant === 'gradient' ? 'contained' : variant}
      size={size}
      ownerState={{ color, variant, size, circular, iconOnly, darkMode }}
    >
      {children}
    </MDButtonRoot>
  );
});

MDButton.displayName = 'MDButton';

MDButton.defaultProps = {
  size: 'medium',
  variant: 'contained',
  color: 'white',
  circular: false,
  iconOnly: false
};

export default MDButton;
