import colors from 'assets/theme-dark/base/colors';

const { text } = colors;

export default {
  styleOverrides: {
    root: {
      color: text.main
    }
  }
};
