import { AUTH_MS } from 'constants/api';
import { authMS } from 'requests';

export const requestResetPassword = (email: string) =>
  authMS(AUTH_MS.RESET_PASSWORD.replace(':email', email));

export const getChangePassword = (scrambledPayload: string) =>
  authMS(AUTH_MS.CHANGE_PASSWORD, {
    headers: {
      'x-auth-hash': scrambledPayload
    }
  });
