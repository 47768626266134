// @mui material components
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 React components
import MDBox, { IMDBoxProp } from 'components/MDBox';

// Custom styles for the SidenavCollapse
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText
} from 'examples/Sidenav/styles/sidenavCollapse';

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from 'context';
import { forwardRef } from 'react';

const SidenavCollapse = forwardRef<any, ISidenavCollapseProps & IMDBoxProp>(
  ({ icon, name, active, ...rest }, ref) => {
    const [controller] = useMaterialUIController();
    const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;

    return (
      <ListItem ref={ref} component="li">
        <MDBox
          {...rest}
          sx={(theme) =>
            collapseItem(theme, {
              active,
              transparentSidenav,
              whiteSidenav,
              darkMode
            })
          }
        >
          <ListItemIcon
            sx={(theme) =>
              collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })
            }
          >
            {typeof icon === 'string' ? (
              <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
            ) : (
              icon
            )}
          </ListItemIcon>

          <ListItemText
            primary={name}
            sx={(theme) =>
              collapseText(theme, {
                miniSidenav,
                transparentSidenav,
                active
              })
            }
          />
        </MDBox>
      </ListItem>
    );
  }
);

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  active: false
};

SidenavCollapse.displayName = 'SidenavCollapse';

// Typechecking props for the SidenavCollapse
interface ISidenavCollapseProps {
  icon: React.ReactNode;
  name: string;
  active?: boolean;
}

export default SidenavCollapse;
