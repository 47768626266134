import boxShadows from 'assets/theme-dark/base/boxShadows';
import typography from 'assets/theme-dark/base/typography';
import colors from 'assets/theme-dark/base/colors';
import borders from 'assets/theme-dark/base/borders';
import pxToRem from 'assets/theme-dark/functions/pxToRem';

const { md } = boxShadows;
const { size } = typography;
const { text, background } = colors;
const { borderRadius } = borders;

export default {
  defaultProps: {
    disableAutoFocusItem: true
  },

  styleOverrides: {
    paper: {
      minWidth: pxToRem(160),
      boxShadow: md,
      padding: `${pxToRem(16)} ${pxToRem(8)}`,
      fontSize: size.sm,
      color: text.main,
      textAlign: 'left',
      backgroundColor: `${background.card} !important`,
      borderRadius: borderRadius.md
    }
  }
};
