import { AUTH_MS } from 'constants/api';
import { authMS } from 'requests';

interface IRefreshTokenResponse {
  access_token: string;
}

const call = () => authMS.get<IRefreshTokenResponse>(AUTH_MS.REFRESH_TOKEN);

export default call;
