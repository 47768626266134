import BOLink from 'components/BOLink';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { Page } from 'constants/route';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useStore } from 'store';
import { Sandbox } from 'utils';

const SandboxBanner = () => {
  const { AuthStore: Auth } = useStore();
  const route = useRouter();

  const switchToLive = () => {
    Sandbox.toggle(!Auth.isSandbox);
    route.reload();
    Auth.logout();
  };

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setIsLoading(false), 1000);
  }, []);

  if (isLoading) {
    return <> </>;
  }

  return (
    <MDBox
      sx={{
        display: 'flex',
        fontSize: '1rem !important',
        position: 'sticky',
        color: 'white!important',
        top: 0,
        flexFlow: { xs: 'column', md: 'row' },
        justifyContent: 'center',
        alignItems: 'center',
        '& a': {
          fontWeight: 'bold',
          textDecoration: 'underline!important'
        }
      }}
      variant="gradient"
      bgColor="error"
    >
      <MDTypography textAlign="center" color="white">
        You are currently running in {Auth?.isSandbox ? 'Test Mode (Sandbox)' : 'Live Mode'}
      </MDTypography>
      <BOLink nextHref={Page.Auth.login} onClick={switchToLive} ml={{ xs: 0, md: '2rem' }}>
        Switch to {Auth?.isSandbox ? 'Live Mode' : 'Test Mode (Sandbox)'}
      </BOLink>
    </MDBox>
  );
};

export default observer(SandboxBanner);
