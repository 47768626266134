import { forwardRef } from 'react';

import MDBoxRoot, { TMDBoxRootColoredShadow, TMDBoxRootVariant } from 'components/MDBox/MDBoxRoot';
import { BoxProps } from '@mui/material/Box';

const MDBox = forwardRef<any, IMDBoxProp>(
  ({ variant, bgColor, color, opacity, borderRadius, shadow, coloredShadow, ...rest }, ref) => (
    <MDBoxRoot
      {...rest}
      ref={ref}
      ownerState={{ variant, bgColor, color, opacity, borderRadius, shadow, coloredShadow }}
    />
  )
);

MDBox.displayName = 'MDBox';

MDBox.defaultProps = {
  variant: 'contained',
  bgColor: 'transparent',
  color: 'dark',
  opacity: 1,
  borderRadius: 'none',
  shadow: 'none',
  coloredShadow: 'none'
};

export interface IMDBoxProp extends BoxProps {
  variant?: TMDBoxRootVariant;
  bgColor?: string;
  color?: string;
  opacity?: number;
  borderRadius?: string;
  shadow?: string;
  coloredShadow?: TMDBoxRootColoredShadow;
}

export default MDBox;
