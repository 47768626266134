import colors from 'assets/theme-dark/base/colors';
import borders from 'assets/theme-dark/base/borders';
import pxToRem from 'assets/theme-dark/functions/pxToRem';
import { ComponentsProps } from '@mui/material/styles/props';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { Theme } from '@mui/material/styles/createTheme';

const { background } = colors;
const { borderRadius } = borders;

export default {
  styleOverrides: {
    root: {
      width: pxToRem(250),
      whiteSpace: 'nowrap',
      border: 'none'
    },

    paper: {
      width: pxToRem(250),
      backgroundColor: background.sidenav,
      height: `calc(100vh - ${pxToRem(32)})`,
      margin: pxToRem(16),
      borderRadius: borderRadius.xl,
      border: 'none'
    },

    paperAnchorDockedLeft: {
      borderRight: 'none'
    }
  }
} as {
  defaultProps?: ComponentsProps['MuiDrawer'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiDrawer'];
};
